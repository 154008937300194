<template>
   <div>
      <img
         v-if="previewImage"
         :width="width"
         :height="height"
         :alt="alt"
         :class="classes"
         :src="previewImage"
      />
   </div>
</template>
<script setup>
   import { DefaultAssets } from '~/assets/DefaultAssets';
   import apiRoutes from '~/const/apiRoutes';

   const props = defineProps([
      'imageId',
      'role',
      'classes',
      'alt',
      'width',
      'height',
   ]);
   const previewImage = ref(DefaultAssets?.images?.user);
   const fetchPreviewImage = async () => {
      let endpoint = props.role === 'therapist' ? useTherapistApi : useIFetch;
      if (props.imageId) {
         const { data } = await endpoint(
            apiRoutes.upload.getFile(props.imageId)
         );
         if (data?.value)
            previewImage.value = URL?.createObjectURL(data?.value);
      } else {
         previewImage.value = DefaultAssets?.images?.user;
      }
   };
   onMounted(async () => {
      await fetchPreviewImage();
   });
</script>
